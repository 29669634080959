import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Whakamatakitaki from "../components/whakamatakitaki";
import Kupu from "../components/kupu";

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

function keiTeKainga() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulKeiTeKainga {
        edges {
          node {
            termEnglish
            termMaori
            kupuEnglish
            kupuMaori
          }
        }
      }
    }
  `);
  const info = shuffle(data.allContentfulKeiTeKainga.edges);
  return (
      <Layout>
        <SEO title="Kei te Kāinga" />
        <Whakamatakitaki collectionName="Kei te Kāinga" subtitle="At Home">
          {info.map(x => (
            <Kupu node={x.node} />
          ))}
        </Whakamatakitaki>
      </Layout>
  );
}

export default keiTeKainga